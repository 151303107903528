import React from "react";
import Nav from "./Components/Nav.js";
import Footer from "./Components/Footer.js";
import { HashLink as Link } from "react-router-hash-link";

function Home() {
  return (
    <div>
      <Nav />

      <main className="main_home" id="cima">
        {/*___________________________________________________SLIDER____________________________________________________*/}

        <div className="row " id="Ancora_Home_topo">
          <div
            id="carouselExampleIndicators"
            className="carousel slide px-0 "
            data-bs-ride="carousel"
          >
            <div
              className="carousel-indicators Carousel_1920_home"
              id="botoes_xs"
            >
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                id="btn_Produtos"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                id="btn_Empresa"
                data-bs-slide-to={1}
                aria-label="Slide 2"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                id="btn_Contactos"
                data-bs-slide-to={2}
                aria-label="Slide 3"
              />
            </div>
            <div className="carousel-inner" id="Tamanho_slider">
              <div className="carousel-item active" id="Contentor_Slogan">
                <Link to="#Ancora_Home_Produtos">
                  <div className="cont_home_img1">
                    <img
                      src="Assets/Home/Principal.png"
                      id="imagem_principal_xs"
                      className="d-block w-100"
                      alt="Paletes"
                    />
                  </div>
                </Link>
                <Link to="#Ancora_Home_Produtos">
                  <img
                    src="Assets/Home/Slogan_Produtos.svg"
                    className="Slogan_Produtos"
                  />
                </Link>
              </div>
              <div className="carousel-item" id="Contentor_Empresa">
                <Link to="#Ancora_Home_Empresa">
                  <div className="cont_home_img2">
                    <img
                      src="Assets/Home/Segunda.png"
                      id="imagem_principal_xs"
                      className="d-block w-100"
                      alt="Paletes empilhadas"
                    />
                  </div>
                </Link>
                <Link to="#Ancora_Home_Empresa">
                  <div className="Contentor_Sloga_Empresa">
                    <h1 className="Sloga_Empresa animate__animated animate__backInRight">
                      QUALIDADE E FIABILIDADE
                    </h1>
                  </div>
                </Link>
              </div>
              <div className="carousel-item" id="Contentor_Contactos">
                <Link to="#Ancora_Home_Contactos">
                  <div className="cont_home_img3">
                    <img
                      src="Assets/Home/Terceira.png"
                      id="imagem_principal_xs"
                      className="d-block w-100"
                      alt="Contactos"
                    />
                  </div>
                </Link>
                <Link to="#Ancora_Home_Contactos">
                  <div className="Contentor_Sloga_Contactos">
                    <h2 className="  Sloga_Contactos animate__animated animate__zoomIn">
                      NÃO HESITE EM NOS CONTACTAR
                    </h2>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*___________________________________________________SEGUNDA____________________________________________________*/}

        <div className="row  home_segunda_xs " data-aos="fade-down">
          <div
            className="col-sm-12 col-md-6 col-xs-12 Home_Segunda_esq text-center"
            id="Ancora_Home_Produtos"
          >
            <img
              src="Assets/Home/Circulos.svg"
              className="img_circulos"
              alt="Imagens circulares de paletes"
            />
          </div>

          <div className="col-sm-12 col-md-6 col-xs-12   Home_Segunda_dir">
            <div className="Contentor_HomeProdutos ">
              <div className="OutroCont_HomeProdutos">
                <img src="Assets/Home/Linha.svg" alt="Produtos" />
                <span className="Span_HomeProdutos">Produtos</span>
                <h2 className="Span_HomeTitulo mt-2 ">
                  O APOIO DA SUA
                  <br />
                  LOGÍSTICA MAIS RESISTENTE
                </h2>
                <p className="col-md-9 col-sm-10 offset-sm-1 offset-lg-0 col-xs-3  p_HomeProdutos text-justify mt-4">
                  São mais de 10 anos destacados pela qualidade e eficácia do
                  produto. Na nossa atuação utilizamos métodos construtivos
                  consolidados. Acreditamos na prática da engenharia associada à
                  tecnologia e à inovação, tendo a resistência da sua logística
                  no centro da nossa operação.
                </p>
                {/* <a
                  onClick={() => {
                    window.location.href = "/#/produtos";
                  }}
                > */}
                <Link to="/produtos">
                  <button
                    type="button"
                    className="btn btn-outline-warning shadow-none col-9 mt-2 "
                    id="btn_HomeProdutos"
                  >
                    Ver produtos
                  </button>
                </Link>
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>

        {/*___________________________________________________TERCEIRA____________________________________________________*/}
        <div className="row Home_Terceira home_terceira_xs ">
          <div
            className="Home_Terceira_Section col-sm-6 col-xs-12 mt-5"
            id="Ancora_Home_Empresa"
          >
            <div className="mx-4">
              <img
                src="Assets/Home/Linha.svg"
                className="Home_Terceira_Linha"
                alt="Empresa"
              />
              <span className="Home_Terceira_Span">Empresa</span>
            </div>
            <div className="text-center" id="margem_terceira_home_1920">
              <div className="Home_Terceira_titulo  ">
                SOMOS A BASE DO SEU NEGÓCIO
              </div>
              <div className=" Home_Terceira_text">
                Venha conhecer a nossa empresa e todos os conceitos que
                <br />
                caracterizam e diferenciam os nossos serviços
              </div>
            </div>
          </div>
          <div className="  text-center" data-aos="fade-down">
            <div className="Home_Terceira_Cartao1  " id="Home_Terceira_Cartao1">
              <Link to="/sobre-nos#caracteristicas">
                <img
                  src="Assets/Home/Caracteristicas.png"
                  className="mt-5 "
                  alt="Características"
                />
              </Link>

              <span>Características</span>
            </div>
            <div
              className="Home_Terceira_Cartao2  offset-1 "
              id="Home_Terceira_Cartaonovo2"
            >
              <Link to="/sobre-nos#seguranca">
                <img
                  src="Assets/Home/Seguranca.png"
                  id="Home_Cartao2"
                  alt="Segurança"
                />
              </Link>
              <span>Segurança</span>
            </div>
            <div className="Home_Terceira_Cartao2">
              <Link to="/sobre-nos#beneficios">
                <img
                  src="Assets/Home/Beneficios.png"
                  id="Home_Cartao3"
                  alt="Benefícios"
                />
              </Link>
              <span id="Beneficios">Benefícios</span>
            </div>
            <div
              className="Home_Terceira_Cartao2"
              id="Home_Terceira_Cartaonovo2"
            >
              <Link to="/sobre-nos#ambiente">
                <img
                  src="Assets/Home/Ambiente.png"
                  id="Home_Cartao4"
                  alt="Ambiente"
                />
              </Link>
              <span id="Ambiente">Ambiente</span>
            </div>
          </div>
        </div>
        {/*___________________________________________________QUARTA____________________________________________________*/}
        <div className="row  home_quarta_xs">
          <div className="Home_Quarta_Section" id="Ancora_Home_Contactos">
            <img
              src="Assets/Home/Linha.svg"
              className="Home_Quarta_Linha"
              alt="Contacte-nos"
            />
            <span className="Span_HomeProdutos">Contacte-nos</span>
            <div className="conjunto_contactos col-xs-12 " data-aos="fade-up">
              <div className="Contactos_resp " />
              <div className="Contactos2_resp text-center">
                <h3>Entre em Contacto</h3>
              </div>
            </div>
          </div>

          <img
            data-aos="fade-up"
            src="Assets/Home/Contacte-nos.svg"
            className="Home_Quarta_Img "
            alt="Contacte-nos"
            onClick={() => {
              window.location.href = "/#/contactos";
            }}
          />
        </div>

        {/* ____________________Localização ROW______________________*/}
        <div className="row  ">
          <div
            className="col-md-12 home_localizacao offset-lg-1 mt-lg-5"
            id="Ancora_Contactos"
          >
            {/* ____________________Localização ______________________*/}
            <div className="mt-4" id="Ancora_Localizacao">
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <div>
                <span className="Span_Contactos">Localização</span>
              </div>
              <div className="mt-4">
                <img
                  src="Assets/Contactos/IconeGPS.svg"
                  className="iconerelogio"
                  alt="Localização"
                  id="santa"
                />
                <div className="localização_texto">
                  <strong className="strong_contactos mx-2">
                    Santa Luzia, Várzea
                  </strong>
                  <br />
                  <strong className="strong_contactos mx-2">
                    {" "}
                    3660-694 São Pedro do Sul
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 mapa_contactos_xs ">
          <iframe
            className="px-0  vw-100 iframe_mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7188.92221042556!2d-8.081954354923015!3d40.749498303882724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23416526576a11%3A0x73bc1d79b0cb853d!2sSantos+%26+Silva!5e0!3m2!1spt-PT!2spt!4v1507994494986"
          />
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Home;
