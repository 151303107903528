import React, { useEffect } from "react";
import Nav from "./Components/Nav.js";
import Footer from "./Components/Footer.js";
import { HashLink as Link } from "react-router-hash-link";

function SobreNos() {
  useEffect(() => {
    document.title = "Sobre Nós - Santos & Silva";
    Sublinhado_SobreNos();
  }, []);
  return (
    <div>
      <Nav />

      <main className="main_sobrenos" id="cima">
        {/*___________________________________________________SLIDER____________________________________________________*/}
        <div className="row " id="Ancora_Home_topo">
          <div
            id="carouselExampleIndicators"
            className="carousel slide px-0 "
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="carousel_sobrenos">
              <Link to="#Ancora_SobreNos_Fazemos">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Fazemos"
                  className="SobreNos_Botoes"
                />
              </Link>
              <Link to="#Ancora_SobreNos_Seguranca">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Seguranca"
                  className="SobreNos_Botoes"
                />
              </Link>

              <Link to="#Ancora_SobreNos_Beneficios">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Beneficios"
                  className="SobreNos_Botoes"
                />
              </Link>
              <Link to="#Ancora_SobreNos_Ambiente">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Ambiente"
                  className="SobreNos_Botoes"
                />
              </Link>
            </div>
            <div className="carousel-inner" id="Tamanho_slider">
              <div
                className="carousel-item active contentor_sobrenos"
                id="Contentor_Slogan"
              >
                <img
                  src="Assets/SobreNós/Sobre.png"
                  className="d-block w-100 SObre_imagem"
                  alt="Paletes"
                />
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________SEGUNDA____________________________________________________*/}
        <div className="row SobreNos_Segunda">
          <div className="offset-xl-1 col-xl-11 col-sm-12  col-xs-12 SobreNos_Colunas">
            <div
              className="Produto_Segunda_Margem Fazemos_Segunda col-xl-4  col-sm-12 col-xs-12"
              id="Ancora_SobreNos_Fazemos"
              data-aos="fade-right"
            >
              <img src="Assets/Home/Linha.svg" alt="O que fazemos" />
              <div className="O_que_fazemos mt-2" id="caracteristicas">
                O que fazemos
              </div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  Santos &amp; Silva nasceu em 1967 em São Pedro do Sul,
                  distrito de Viseu, com atividade serração de madeiras e
                  carpintaria.
                </p>
                <p>
                  A atividade da empresa reparte-se em duas grandes áreas de
                  negócio: serração de madeiras e a fabricação de paletes,
                  embalagens de madeira, madeira termotratada, europalete(EPAL)
                </p>
                <p>
                  e entre diversos outros produtos de madeira: como casca de
                  pinho, estilha e serrim.
                </p>
                <p>
                  Hoje em dia, é uma empresa que está focada no futuro e na
                  inovação em tecnologia para uma maior eficiência produtiva e
                  qualidade do produto acabado.
                </p>
              </div>
              <div className="Fazemos_Premiado mt-4 text-center">
                <div>Premiado líder de excelência</div>
                <div>
                  <img
                    src="Assets/SobreNós/PME.png"
                    className="mt-3"
                    alt="Prémios de excelência 2016"
                  />
                </div>
              </div>
            </div>
            <div
              className="Fazemos_Premiado_Imagem nova_coluna_sobrenos col-xs-12 "
              data-aos="fade-left"
            >
              <img
                src="Assets/SobreNós/Segunda.png"
                className="imagem_primeira_sobrenos_xs"
                alt="O que fazemos"
              />
            </div>
          </div>
        </div>
        {/*___________________________________________________TERCEIRA____________________________________________________*/}
        <div className="row SobreNos_Segunda" id="terceira_sobrenos_lg">
          <div className="offset-1 col-11 SobreNos_Colunas">
            <div
              className="Seguranca_Imagem mt-5 "
              id="Ancora_SobreNos_Seguranca"
              data-aos="fade-right"
            >
              <img
                src="Assets/SobreNós/Terceira.png"
                alt="Segurança"
                id="seguranca"
              />
            </div>
            <div
              className="Produto_Segunda_Margem SobreNos_Terceira col-4"
              id="Ancora_Produtos_Paletes"
              data-aos="fade-left"
            >
              <img src="Assets/Home/Linha.svg" alt="Segurança" />
              <div className="O_que_fazemos mt-2">Segurança</div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  A nossa empresa cumpre com Norma ISPM 15, que visa a deteção e
                  eliminação do Nemátodo da Madeira do Pinheiro e outros
                  organismos prejudiciais, através de procedimentos de
                  tratamento fitossanitário pelo calor – choque térmico (HT).
                </p>
                <p>
                  Este rigoroso processo assegura uma melhor qualidade e
                  durabilidade da nossa madeira
                </p>
                <p>
                  Se estiver com duvidas sobre algum tipo de palete ou madeira
                  serrada ideal para o seu negócio, fale connosco sem
                  compromisso. Nós damos a solução mais adequada para o seu
                  negócio.
                </p>
              </div>
              <div className="MarcaDePalete mt-4 text-center">
                <img
                  src="Assets/SobreNós/Marca.png"
                  className="mt-3"
                  alt="Marca de Palete"
                />
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________TERCEIRA-XS____________________________________________________*/}
        <div
          className="row SobreNos_Segunda text-center "
          id="terceira_sobrenos_xs"
        >
          <div className=" col-12 SobreNos_Colunas ">
            <div
              className="Produto_Segunda_Margem SobreNos_Terceira mudar_marge_terceira"
              data-aos="fade-right"
            >
              <img src="Assets/Home/Linha.svg" alt="Segurança" id="seguranca" />
              <div className="O_que_fazemos mt-2">Segurança</div>
              <div className="Fazemos_texto  mt-3">
                <p>
                  A nossa empresa cumpre com Norma ISPM 15, que visa a deteção e
                  eliminação do Nemátodo da Madeira do Pinheiro e outros
                  organismos prejudiciais, através de procedimentos de
                  tratamento fitossanitário pelo calor – choque térmico (HT).
                </p>
                <p>
                  Este rigoroso processo assegura uma melhor qualidade e
                  durabilidade da nossa madeira
                </p>
                <p>
                  Se estiver com duvidas sobre algum tipo de palete ou madeira
                  serrada ideal para o seu negócio, fale connosco sem
                  compromisso. Nós damos a solução mais adequada para o seu
                  negócio.
                </p>
              </div>
              <div className="MarcaDePalete mt-4 text-center">
                <img
                  src="Assets/SobreNós/Marca.png"
                  className="mt-3"
                  alt="Marca de Palete"
                />
              </div>
            </div>
            <div
              className="Seguranca_Imagem mt-5 col-12 vh-100 px-0 "
              id="ancora_imagem_terceira_xs"
              data-aos="fade-left"
            ></div>
          </div>
        </div>
        {/*___________________________________________________QUARTA____________________________________________________*/}
        <div className="row SobreNos_Segunda">
          <div className="offset-xl-1 col-xl-11 col-sm-12 col-xs-12 SobreNos_Colunas">
            <div
              className="Produto_Segunda_Margem Fazemos_Segunda  col-xl-4 col-sm-4  col-xs-12"
              id="Ancora_SobreNos_Beneficios"
              data-aos="fade-right"
            >
              <img
                src="Assets/Home/Linha.svg"
                alt="Beneficios"
                id="beneficios"
              />
              <div className="O_que_fazemos mt-2">Beneficios</div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  A nossa empresa tem uma frota própria que garante um serviço
                  completo, rápido e mais flexível ao cliente.
                </p>
                <p>
                  {" "}
                  A atuação da nossa empresa tem como norte a aplicação de uma
                  engenharia de Valor com preservação da qualidade.
                </p>
                <p>
                  Fazemos isso levando toda a técnica, competência e experiência
                  de nossos engenheiros para o seu empreendimento, seja qual for
                  o momento em que nos inserimos nele — na construção em si
                  desde o desenvolvimento do projeto.
                </p>
                <p>
                  Levamos, também, nosso rigoroso processo de qualificação de
                  fornecedores e as práticas em segurança, sustentabilidade,
                  personalização e desempenho que são padrão da Santos &amp;
                  SIlva e estão presentes em todos os projetos em que deixamos
                  nossa marca
                </p>
              </div>
            </div>
            <div
              className="Fazemos_Premiado_Imagem "
              id="quarta_noxs"
              data-aos="fade-left"
            >
              <img
                src="Assets/SobreNós/Quarta.png"
                className="quarta_desaparece_xs"
                alt="Benefícios"
              />
            </div>
          </div>
        </div>
        {/*___________________________________________________QUINTA____________________________________________________*/}
        <div className="row SobreNos_Segunda" id="quinta_sobrenos">
          <div className="offset-1 col-11 SobreNos_Colunas">
            <div
              className="Seguranca_Imagem mt-5"
              id="Ancora_SobreNos_Ambiente"
              data-aos="fade-right"
            >
              <img
                src="Assets/SobreNós/Quinta.png"
                alt="Ambiente"
                id="ambiente"
              />
            </div>
            <div
              className="Produto_Segunda_Margem SobreNos_Terceira col-4"
              id="Ancora_Produtos_Paletes"
              data-aos="fade-left"
            >
              <img src="Assets/Home/Linha.svg" alt="Ambiente" />
              <div className="O_que_fazemos mt-2">Ambiente</div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  A proteção ao meio ambiente e a sustentabilidade se tornaram
                  duas demandas essenciais para a sociedade como um todo.
                </p>
                <p>
                  A adoção de uma postura sustentável requer uma transformação
                  nos processos de produção, cotidiano e mentalidade de todos
                  nós.
                </p>
                <p>
                  A Santos &amp; Silva exerce uma função primordial quando o
                  assunto é meio ambiente e sustentabilidade.
                </p>
                <p>
                  A nossa empresa tem uma equipa especializada na exploração
                  florestal, havendo uma seleção cuidada dos recursos
                  necessários.
                </p>
                <p>
                  Atualmente devido ao problema acrescido da desflorestação,
                  contribuimos para o bem do nosso planeta com plantações de
                  árvores.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________QUINTA-XS____________________________________________________*/}
        <div className="row SobreNos_Segunda " id="quinta_sobrenos_xs">
          <div className="col-12 SobreNos_Colunas">
            <div className="Produto_Segunda_Margem SobreNos_Terceira " id>
              <img src="Assets/Home/Linha.svg" alt="Ambiente" />
              <div className="O_que_fazemos mt-2">Ambiente</div>
              <div className="Fazemos_texto  mt-3">
                <p>
                  A proteção ao meio ambiente e a sustentabilidade se tornaram
                  duas demandas essenciais para a sociedade como um todo.
                </p>
                <p>
                  A adoção de uma postura sustentável requer uma transformação
                  nos processos de produção, cotidiano e mentalidade de todos
                  nós.
                </p>
                <p>
                  A Santos &amp; Silva exerce uma função primordial quando o
                  assunto é meio ambiente e sustentabilidade.
                </p>
                <p>
                  A nossa empresa tem uma equipa especializada na exploração
                  florestal, havendo uma seleção cuidada dos recursos
                  necessários.
                </p>
                <p>
                  Atualmente devido ao problema acrescido da desflorestação,
                  contribuimos para o bem do nosso planeta com plantações de
                  árvores.
                </p>
              </div>
            </div>
            <div
              className="Seguranca_Imagem mt-5 col-12 vh-100 px-0 "
              id="Ancora_SobreNos_Ambiente"
            ></div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
  function Sublinhado_SobreNos() {
    document.getElementById("nav_underline_SobreNos").style.cssText =
      " border-bottom: 4px solid #f27f3d;";
    var styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.innerHTML =
      "#nav_underline_SobreNos:after {content: unset; position: unset; bottom: unset; left: unset; width: unset; height: unset;background-color: unset;opacity: unset;transition:unset;}";
  }
}

export default SobreNos;
