import React, { useEffect, useCallback } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Nav from "./Components/Nav.js";
import Footer from "./Components/Footer.js";
import { HashLink as Link } from "react-router-hash-link";

function Contactos() {
  useEffect(() => {
    document.title = "Contactos - Santos & Silva";
    Sublinhar_DiadeSemana();
    Sublinhado_Contactos();

    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js?hl=pt-PT";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [state, handleSubmit] = useForm("mwkydbdn");
  if (state.succeeded) {
    document.getElementById("alert_formulario").style.display = "none";
    document.getElementById("Alerta_sucesso").style.display = "block";
  }

  function Submetendo(e) {
    let valor = document.getElementById("textarea").value;
    let verificacao = /[a-z]{1,30}(,[a-z]{1,30})*/.test(valor);

    if (verificacao == false) {
      document.getElementById("alert_formulario").style.display = "block";
      document.getElementById("Alerta_sucesso").style.display = "none";
    } else {
      document.getElementById("alert_formulario").style.display = "none";
      document.getElementById("Alerta_sucesso").style.display = "block";

      handleSubmit(e);

      document.getElementById("form_contactos_xs").reset();
    }
    e.preventDefault();
  }

  return (
    <div>
      <Nav />
      <main className="main_contactos" id="cima">
        {/*___________________________________________________SLIDER____________________________________________________*/}
        <div className="row " id="Ancora_Produtos_Topo">
          <div
            id="carouselExampleIndicators"
            className="carousel slide px-0 "
            data-bs-ride="carousel"
          >
            <div
              className="carousel-indicators contactos_botoes"
              id="Produtos_indicadores"
            >
              <Link to="#Ancora_Contactos">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Contactos_contactos"
                  aria-current="true"
                  className="SobreNos_Botoes"
                  aria-label="Slide 1"
                />
              </Link>
              <Link to="#Ancora_Localizacao">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_GPS"
                  className="SobreNos_Botoes"
                />
              </Link>
            </div>
            <div className="carousel-inner" id="Produtos_Tamanho_slider">
              <div
                className="carousel-item active Contentor_contactos_final"
                id="Contentor_Slogan"
              >
                <Link to="#Ancora_Produtos_Paletes">
                  <img
                    src="Assets/Contactos/Contactos.png"
                    className="d-block w-100 contentor_contactos_xs"
                    alt="Paletes"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________SEGUNDA____________________________________________________*/}
        <div className="row  mt-5">
          <div
            className="Produto_Segunda_Margem offset-md-1 col-md-5  segunda_contactos_xs"
            id="Ancora_Contactos"
          >
            <div data-aos="flip-up">
              <div>
                <img src="Assets/Home/Linha.svg" alt="Paletes" />
                <div>
                  <span className="Span_Contactos">Entre em contacto</span>
                </div>
              </div>
              <div className="mt-4">
                <div class="cotentor_telefone">
                  <img
                    src="Assets/Contactos/IconeTelef.svg"
                    className="imagem_telef_contactxs"
                    alt="Telefone"
                  />
                  <div className="info_numero mx-2 ">
                    <strong className="strong_contactos  ">232 711 424</strong>
                    <p class="chamada ">(Chamada para a rede fixa nacional)</p>
                  </div>
                </div>
                <div className="mt-3">
                  <img src="Assets/Contactos/IconeEmail.svg" alt="Email" />
                  <strong className="strong_contactos mx-2">
                    geral@santosesilva.pt
                  </strong>
                </div>
              </div>
            </div>
            {/* ____________________HORÁRIO ______________________*/}
            <div className="mt-5" data-aos="flip-up">
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <div>
                <span className="Span_Contactos">Horário</span>
              </div>
              <div className="mt-4 conjunto_diasdesemana">
                <img
                  src="Assets/Contactos/IconeRelogio.svg"
                  className="iconerelogio"
                  alt="Horário"
                />
                <ul className="dias_semana">
                  <li class="domingo">domingo</li>
                  <li class="segunda-feira">segunda-feira</li>
                  <li class="terca-feira">terça-feira</li>
                  <li class="quarta-feira">quarta-feira</li>
                  <li class="quinta-feira">quinta-feira</li>
                  <li class="sexta-feira">sexta-feira</li>
                  <li class="sabado">sábado</li>
                </ul>
                <ul className="dias_semana">
                  <li class="domingo">08:00 17:00</li>
                  <li class="segunda-feira">08:00 17:00</li>
                  <li class="terca-feira">08:00 17:00</li>
                  <li class="quarta-feira">08:00 17:00</li>
                  <li class="quinta-feira">08:00 17:00</li>
                  <li class="sexta-feira">08:00 17:00</li>
                  <li class="sabado">encerrado</li>
                </ul>
              </div>
            </div>
            {/* ____________________Localização ______________________*/}
            <div className="mt-4" id="Ancora_Localizacao" data-aos="flip-up">
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <div>
                <span className="Span_Contactos">Localização</span>
              </div>
              <div className="mt-4">
                <img
                  src="Assets/Contactos/IconeGPS.svg"
                  className="iconerelogio"
                  alt="Localização"
                  id="santa"
                />
                <div className="localização_texto">
                  <strong className="strong_contactos mx-2">
                    Santa Luzia, Várzea
                  </strong>
                  <br />
                  <strong className="strong_contactos mx-2">
                    {" "}
                    3660-694 São Pedro do Sul
                  </strong>
                </div>
              </div>
            </div>
          </div>
          {/* __________________________________________________________FORMULÁRIO __________________________________*/}
          <form
            className="col-md-5  formulario Produto_Segunda_Margem "
            id="form_contactos_xs"
            onSubmit={Submetendo}
            // onSubmit={handleSubmit(onSubmit)}
            data-callback="XXX"
            action="https://formspree.io/f/mwkydbdn"
            method="POST"
          >
            <div>
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <div>
                <span className="Span_Contactos">Faça o seu pedido</span>
              </div>
            </div>
            <div
              className="form-group col-sm-10 offset-sm-1 col-md-12 offset-md-0 mt-4"
              id="formulario_text"
            >
              <input
                type="text"
                className="form-control mt-4 "
                id="formulario_text"
                pattern="^[a-zA-Z]+.*$"
                placeholder="Nome de Particular/Nome da Empresa"
                aria-describedby="emailHelp"
                required
              />
              <input
                type="email"
                className="form-control mt-4"
                id="formulario_text"
                placeholder="E-mail"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                required
              />

              <input
                type="number"
                className="form-control mt-4"
                id="formulario_text"
                placeholder="NIF"
                required
              />
              <input
                type="number"
                className="form-control mt-4"
                id="formulario_text"
                placeholder="Telemóvel/Telefone"
                required
              />
              <textarea
                className="form-control mt-4"
                id="textarea"
                placeholder="Mensagem"
                minlength="30"
                maxlength="1000"
                validation-regex="^[a-zA-Z]+.*$"
                required
              />
            </div>
            <div className="form-group form-check"></div>

            <div
              className="g-recaptcha mx-auto"
              data-callback="recaptchaCallback"
              data-sitekey="6LdtDlogAAAAAHt7On0Yb5sTlCKIdEWec53aZRgT"
              style={{
                transform: "scale(1)",
              }}
            />

            <br></br>
            <div
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
              id="alert_formulario"
            >
              <strong>
                A mensagem não corresponde aos requisitos mínimos!
              </strong>{" "}
              Por favor, preencha uma mensagem maior que 30 caracteres.
            </div>

            <button
              type="submit"
              className="btn btn-outline-warning Botao_submeter shadow-none  col-md-12  mt-2 "
              id="btn_HomeProdutos"
            >
              Enviar
            </button>
            {/*___________________________________________________ALERT SUCESSO____________________________________________________*/}
            <div
              class="alert alert-success alert-dismissible fade show mt-lg-4 mt-sm-0 mt-xs-0 mt-md-4 text-center"
              role="alert"
              id="Alerta_sucesso"
            >
              <strong>Mensagem enviada com sucesso!</strong> Responderemos o
              mais rápido possível. Obrigado!
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </form>
        </div>
        {/*______________________________________________________________________________________________________________*/}
        <div className="row mt-5 mapa_contactos_xs ">
          <iframe
            className="iframe_mapa px-0  vw-100"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7188.92221042556!2d-8.081954354923015!3d40.749498303882724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23416526576a11%3A0x73bc1d79b0cb853d!2sSantos+%26+Silva!5e0!3m2!1spt-PT!2spt!4v1507994494986"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
  function Sublinhado_Contactos() {
    document.getElementById("nav_underline_Contactos").style.cssText =
      " border-bottom: 4px solid #f27f3d;";
    var styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.innerHTML =
      "#nav_underline_Contactos:after {content: unset; position: unset; bottom: unset; left: unset; width: unset; height: unset;background-color: unset;opacity: unset;transition:unset;}";
  }

  function Sublinhar_DiadeSemana() {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const d = new Date();
    let day = weekday[d.getDay()];

    switch (day) {
      case "Sunday":
        let elementos_domingo = document.getElementsByClassName("domingo");
        for (let i = 0; i < elementos_domingo.length; i++) {
          elementos_domingo[i].style.fontWeight = "bolder";
        }

        break;
      case "Monday":
        let elementos_segunda =
          document.getElementsByClassName("segunda-feira");
        for (let i = 0; i < elementos_segunda.length; i++) {
          elementos_segunda[i].style.fontWeight = "bolder";
        }
        break;

      case "Tuesday":
        let elementos_terca = document.getElementsByClassName("terca-feira");
        for (let i = 0; i < elementos_terca.length; i++) {
          elementos_terca[i].style.fontWeight = "bolder";
        }
        break;

      case "Wednesday":
        let elementos_quarta = document.getElementsByClassName("quarta-feira");
        for (let i = 0; i < elementos_quarta.length; i++) {
          elementos_quarta[i].style.fontWeight = "bolder";
        }
        break;
      case "Thursday":
        let elementos_quinta = document.getElementsByClassName("quinta-feira");
        for (let i = 0; i < elementos_quinta.length; i++) {
          elementos_quinta[i].style.fontWeight = "bolder";
        }
        break;
      case "Friday":
        let elementos_sexta = document.getElementsByClassName("sexta-feira");
        for (let i = 0; i < elementos_sexta.length; i++) {
          elementos_sexta[i].style.fontWeight = "bolder";
        }
        break;
      case "Saturday":
        let elementos_sabado = document.getElementsByClassName("sabado");
        for (let i = 0; i < elementos_sabado.length; i++) {
          elementos_sabado[i].style.fontWeight = "bolder";
        }
        break;
      default:
    }
  }
}

export default Contactos;
