import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { HashRouter, Route, Routes } from "react-router-dom";

//------------------------------------------------IMPORTS VIEWS-----------------------------------------------
import Home from "./Views/Home.js";
import Produtos from "./Views/Produtos.js";
import SobreNos from "./Views/SobreNos.js";
import Galeria from "./Views/Galeria.js";
import Contactos from "./Views/Contactos.js";
import Administracao from "./Views/Administracao.js";

//-------------ENGLISH-------------------------------
import Home_EN from "./Views/Home_EN.js";
import Produtos_EN from "./Views/Produtos_EN.js";
import SobreNos_EN from "./Views/SobreNos_EN.js";
import Galeria_EN from "./Views/Galeria_EN.js";
import Contactos_EN from "./Views/Contactos_EN.js";
//------------------------------------------------CLASS APP-----------------------------------------------
function App() {
  const RELATIVE_PATH = window.location.pathname;
  if (
    !RELATIVE_PATH.includes("#/") &&
    RELATIVE_PATH != "" &&
    RELATIVE_PATH != "/"
  ) {
    window.location.href = "/";
  }
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/sobre-nos" element={<SobreNos />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/contactos" element={<Contactos />} />
        <Route path="/administracao" element={<Administracao />} />
        {/* -------------ENGLISH------------------------------- */}
        <Route path="/home" element={<Home_EN />} />
        <Route path="/products" element={<Produtos_EN />} />
        <Route path="/about-us" element={<SobreNos_EN />} />
        <Route path="/gallery" element={<Galeria_EN />} />
        <Route path="/contacts" element={<Contactos_EN />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
