import React, { useEffect } from "react";
import Nav from "./Components/Nav.js";
import Footer from "./Components/Footer.js";

function Galeria() {
  useEffect(() => {
    document.title = "Galeria - Santos & Silva";
    Sublinhado_Galeria();
  }, []);

  return (
    <div>
      <Nav />

      <main id="cima">
        {/*___________________________________________________SLIDER____________________________________________________*/}
        <div className="row " id="Ancora_Produtos_Topo">
          <div
            id="carouselExampleIndicators"
            className="carousel slide px-0 "
            data-bs-ride="carousel"
          >
            <div className="carousel-inner" id="Produtos_Tamanho_slider">
              <div
                className="carousel-item active text_slider_galeria_xs"
                id="text_slider_galeria_xs"
              >
                <div className="cont_img_galeria">
                  <img
                    src="Assets/Galeria/Galeria_Palete.png"
                    className="d-block w-100 esconder_imagem_galeria"
                    id="img_galeria"
                    alt="Paletes"
                  />
                </div>
                <div className="Contentor_Sloga_Empresa">
                  <h1 className="Sloga_Galeria animate__animated animate__backInRight">
                    Conheça o nosso Portefólio
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________SEGUNDA____________________________________________________*/}
        <div className="row Galeria_Segunda ">
          <div className="px-0">
            <hr className="hr_galeria" />
            <span className="circulo_galeria" />
            <div className="Conjunto_Galeria ">
              <hr className="hr_galeria2" />
              <div className="Icones_Galeria offset-lg-6">
                {/* _____________TODOS_________________________ */}
                <figure
                  className="Figura1_Galeria "
                  id="Todos_Ativar_laranja"
                  onClick={Filtrar_Todos}
                >
                  <div className="Figura1_Galeria_contentor">
                    <img src="Assets/Galeria/Todos.svg" alt="Todos" />
                  </div>
                  <figcaption className="Legenda1 text-center mt-2">
                    Todos
                  </figcaption>
                </figure>
                {/* _____________PALETES_________________________ */}
                <figure
                  className="Figura1_Galeria Galeria_IconePaletes"
                  onClick={Filtrar_Paletes}
                  id="Paletes_Ativar_laranja"
                >
                  <div className="Figura1_Galeria_contentor">
                    <img src="Assets/Galeria/Paletes.svg" alt="Todos" />
                  </div>
                  <figcaption className="Legenda1 text-center mt-2">
                    Paletes
                  </figcaption>
                </figure>
                {/* _____________MADEIRA SERRADA_________________________ */}
                <figure
                  className="Figura1_Galeria Galeria_IconePaletes"
                  id="Serrada_Ativar_laranja"
                  onClick={Filtrar_Serrada}
                >
                  <div className="Figura1_Galeria_contentor">
                    <img
                      src="Assets/Galeria/Serrada.svg"
                      id="Imagem_MadeiraSerrada"
                      alt="Todos"
                    />
                  </div>
                  <figcaption className="Legenda1 text-center mt-2">
                    Madeira Serrada
                  </figcaption>
                </figure>
                {/* _____________MADEIRA_________________________ */}
                <figure
                  className="Figura1_Galeria Galeria_IconePaletes"
                  id="Madeira_Ativar_laranja"
                  onClick={Filtrar_Madeira}
                >
                  <div className="Figura1_Galeria_contentor">
                    <img
                      src="Assets/Galeria/Madeira.svg"
                      id="Imagem_MadeiraSerrada"
                      alt="Todos"
                    />
                  </div>
                  <figcaption className="Legenda1 text-center mt-2">
                    Madeira
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>

          {/* ________________________________________________________IMAGENS_________________________________________________ */}
          <div
            className="offset-lg-1 col-lg-10 mt-5 conjunto_galeria_xs"
            data-aos="zoom-in-up"
          >
            <div>
              <div>
                {/* ____________________________________1_________________________________________________ */}
                <a
                  className="example-image-link "
                  href="Assets/Galeria/Primeira.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_PrimeiraCaixa"
                >
                  <div className="Contentor_cor_galeria">
                    <img
                      src="Assets/Galeria/Primeira.png"
                      className="Galeria_Primeira col-4 "
                      id="Galeria_PrimeiraCaixa"
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________2_________________________________________________ */}
                <a
                  className="example-image-link "
                  href="Assets/Galeria/Segunda.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_SegundaCaixa"
                >
                  <div className="Contentor_cor_galeria  col-4 mx-lg-1">
                    <img
                      src="Assets/Galeria/Segunda.png"
                      className="Galeria_Primeira "
                      id="Galeria_SegundaCaixa"
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________3_________________________________________________ */}
                <a
                  className="example-image-link "
                  href="Assets/Galeria/Terceira.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_TerceiraCaixa"
                >
                  <div className="Contentor_cor_galeria  col-4">
                    <img
                      src="Assets/Galeria/Terceira.png"
                      className="Galeria_Primeira "
                      id="Galeria_TerceiraCaixa"
                      alt="Todos"
                    />
                  </div>
                </a>
              </div>

              {/* ___________________________________4_________________________________________________ */}
              <div className="mt-1" id="Conjunto_Segundo">
                <a
                  className="example-image-link lightbox_Conjunto_Segundo"
                  href="Assets/Galeria/Quarta.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_QuartaCaixa"
                >
                  <div className="Contentor_cor_galeria col-4">
                    <img
                      src="Assets/Galeria/Quarta.png"
                      className="Galeria_Primeira "
                      id="Galeria_QuartaCaixa"
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________5_________________________________________________ */}
                <a
                  className="example-image-link lightbox_Conjunto_Segundo"
                  href="Assets/Galeria/Quinta.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_QuintaCaixa"
                >
                  <div className="Contentor_cor_galeria col-4 mx-lg-1">
                    <img
                      src="Assets/Galeria/Quinta.png"
                      className="Galeria_Primeira "
                      id="Galeria_QuintaCaixa"
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________6_________________________________________________ */}
                <a
                  className="example-image-link lightbox_Conjunto_Segundo"
                  href="Assets/Galeria/Sexta.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_SextaCaixa"
                >
                  <div className="Contentor_cor_galeria col-4 ">
                    <img
                      src="Assets/Galeria/Sexta.png"
                      className="Galeria_Primeira "
                      id="Galeria_SextaCaixa"
                      alt="Todos"
                    />
                  </div>
                </a>
              </div>
              {/* ___________________________________7_________________________________________________ */}
              <div className="mt-1" id="Conjunto_8910">
                <a
                  className="example-image-link  lightbox_Conjunto_8910"
                  href="Assets/Galeria/7.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_SetimaCaixa"
                >
                  <div className="Contentor_cor_galeria col-4 ">
                    <img
                      src="Assets/Galeria/7.png"
                      className="Galeria_Primeira "
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________8_________________________________________________ */}
                <a
                  className="example-image-link lightbox_Conjunto_8910"
                  href="Assets/Galeria/8.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_oitavacaixa"
                >
                  <div className="Contentor_cor_galeria col-4 mx-lg-1 ">
                    <img
                      src="Assets/Galeria/8.png"
                      className="Galeria_Primeira "
                      alt="Todos"
                      id="img_8"
                    />
                  </div>
                </a>
                {/* ___________________________________9_________________________________________________ */}
                <a
                  className="example-image-link lightbox_Conjunto_8910"
                  href="Assets/Galeria/9.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_nonacaixa"
                >
                  <div className="Contentor_cor_galeria col-4  ">
                    <img
                      src="Assets/Galeria/9.png"
                      className="Galeria_Primeira "
                      alt="Todos"
                    />
                  </div>
                </a>
              </div>
              {/* ___________________________________10_________________________________________________ */}
              <div className="mt-1" id="Conjunto_novo">
                <a
                  className="example-image-link lightbox_Conjunto_novo"
                  href="Assets/Galeria/10.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                >
                  <div className="Contentor_cor_galeria col-4  ">
                    <img
                      src="Assets/Galeria/10.png"
                      className="Galeria_Primeira "
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________11_________________________________________________ */}
                <a
                  className="example-image-link lightbox_Conjunto_novo"
                  href="Assets/Galeria/11.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="lightbox_OnzeCaixa"
                >
                  <div className="Contentor_cor_galeria col-4 mx-lg-1 ">
                    <img
                      src="Assets/Galeria/11.png"
                      className="Galeria_Primeira "
                      alt="Todos"
                    />
                  </div>
                </a>
                {/* ___________________________________12_________________________________________________ */}
                <a
                  className="example-image-link lightbox_Conjunto_novo"
                  href="Assets/Galeria/12.png"
                  data-lightbox="example-set"
                  data-title="Navegue através das laterais da imagem para avançar ou recuar."
                  id="img_12"
                >
                  <div className="Contentor_cor_galeria col-4 ">
                    <img
                      src="Assets/Galeria/12.png"
                      className="Galeria_Primeira "
                      alt="Todos"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );

  //________________________________________________NAV_________________________________________________________

  function Sublinhado_Galeria() {
    document.getElementById("nav_underline_Galeria").style.cssText =
      " border-bottom: 4px solid #f27f3d;";
    var styleElem = document.head.appendChild(document.createElement("style"));

    styleElem.innerHTML =
      "#nav_underline_Galeria:after {content: unset; position: unset; bottom: unset; left: unset; width: unset; height: unset;background-color: unset;opacity: unset;transition:unset;}";
  }

  //________________________________________________FILTROS_________________________________________________________

  function Filtrar_Todos() {
    //______________________________ICON_________________________________________________

    document.getElementById("Madeira_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";
    document.getElementById("Todos_Ativar_laranja").style.filter =
      "invert(50%) sepia(77%) saturate(903%) hue-rotate(338deg) brightness(108%) contrast(90%) ";

    document.getElementById("Paletes_Ativar_laranja").style.filter =
      " invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";
    document.getElementById("Serrada_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%) ";
    //______________________________REPOR_________________________________________________

    //___________________________LIGHTBOX_____________________________________

    let elements_todos = document.getElementsByClassName(
      "lightbox_Conjunto_8910"
    );
    for (var i = 0; i < elements_todos.length; i++) {
      elements_todos[i].setAttribute("data-lightbox", "example-set");
    }

    let conjunto_novo = document.getElementsByClassName(
      "lightbox_Conjunto_novo"
    );
    for (var i = 0; i < conjunto_novo.length; i++) {
      conjunto_novo[i].setAttribute("data-lightbox", "example-set");
    }

    let Conjunto_Segundo = document.getElementsByClassName(
      "lightbox_Conjunto_Segundo"
    );
    for (var i = 0; i < Conjunto_Segundo.length; i++) {
      Conjunto_Segundo[i].setAttribute("data-lightbox", "example-set");
    }
    document
      .getElementById("lightbox_TerceiraCaixa")
      .setAttribute("data-lightbox", "example-set");

    //________________________________________________________________
    document.getElementById("lightbox_PrimeiraCaixa").href =
      "Assets/Galeria/Primeira.png";
    document.getElementById("Galeria_PrimeiraCaixa").src =
      "Assets/Galeria/Primeira.png";
    document.getElementById("Galeria_TerceiraCaixa").style.display = "unset";

    document.getElementById("Conjunto_Segundo").style.display = "block";

    document.getElementById("Galeria_SegundaCaixa").src =
      "Assets/Galeria/Segunda.png";
    document.getElementById("lightbox_SegundaCaixa").href =
      "Assets/Galeria/Segunda.png";

    document.getElementById("Galeria_TerceiraCaixa").src =
      "Assets/Galeria/Terceira.png";
    document.getElementById("lightbox_TerceiraCaixa").href =
      "Assets/Galeria/Terceira.png";

    document.getElementById("Galeria_QuartaCaixa").src =
      "Assets/Galeria/Quarta.png";
    document.getElementById("lightbox_QuartaCaixa").href =
      "Assets/Galeria/Quarta.png";

    document.getElementById("Galeria_QuintaCaixa").src =
      "Assets/Galeria/Quinta.png";
    document.getElementById("lightbox_QuintaCaixa").href =
      "Assets/Galeria/Quinta.png";

    document.getElementById("Galeria_SextaCaixa").src =
      "Assets/Galeria/Sexta.png";
    document.getElementById("lightbox_SextaCaixa").href =
      "Assets/Galeria/Sexta.png";

    document.getElementById("Conjunto_8910").style.display = "inline-block";

    document.getElementById("Conjunto_novo").style.display = "block";
    //________________________________________________________

    document
      .getElementById("lightbox_SetimaCaixa")
      .setAttribute("data-lightbox", "example-set");

    document
      .getElementById("lightbox_nonacaixa")
      .setAttribute("data-lightbox", "example-set");

    document.getElementById("lightbox_SetimaCaixa").style.display =
      "inline-block";
    document.getElementById("lightbox_nonacaixa").style.display =
      "inline-block";
    document.getElementById("img_8").src = "Assets/Galeria/8.png";
    document.getElementById("lightbox_oitavacaixa").href =
      "Assets/Galeria/8.png";

    document.getElementById("lightbox_oitavacaixa").style.display =
      "inline-block";
    document.getElementById("lightbox_oitavacaixa").style.width = "unset";
    document.getElementById("lightbox_oitavacaixa").style.margin = "unset";
    document.getElementById("img_8").style.display = "inline-block";
  }

  function Filtrar_Paletes() {
    //___________________________________________LARANJA_______________________________________________
    document.getElementById("Paletes_Ativar_laranja").style.filter =
      "invert(50%) sepia(77%) saturate(903%) hue-rotate(338deg) brightness(108%) contrast(90%) ";
    document.getElementById("Todos_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";
    document.getElementById("Serrada_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%) ";
    document.getElementById("Madeira_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";
    //___________________________________________MUDAR IMAGENS_________________________________________
    document.getElementById("Conjunto_Segundo").style.display = "block";
    document.getElementById("Galeria_SegundaCaixa").src =
      "Assets/Galeria/Quarta.png";
    document.getElementById("lightbox_SegundaCaixa").href =
      "Assets/Galeria/Quarta.png";

    document.getElementById("Galeria_TerceiraCaixa").style.display = "unset";
    document.getElementById("Galeria_TerceiraCaixa").src =
      "Assets/Galeria/Sexta.png";
    document.getElementById("lightbox_TerceiraCaixa").href =
      "Assets/Galeria/Sexta.png";

    document.getElementById("Galeria_PrimeiraCaixa").src =
      "Assets/Galeria/Primeira.png";
    document.getElementById("lightbox_PrimeiraCaixa").href =
      "Assets/Galeria/Primeira.png";

    document.getElementById("Galeria_QuartaCaixa").src = "Assets/Galeria/8.png";
    document.getElementById("lightbox_QuartaCaixa").href =
      "Assets/Galeria/8.png";

    document.getElementById("Galeria_QuintaCaixa").src = "Assets/Galeria/9.png";
    document.getElementById("lightbox_QuintaCaixa").href =
      "Assets/Galeria/9.png";

    document.getElementById("Galeria_SextaCaixa").src = "Assets/Galeria/10.png";
    document.getElementById("lightbox_SextaCaixa").href =
      "Assets/Galeria/10.png";

    // document.getElementById("Conjunto_8910").style.display = "none";
    // let elements = document.getElementsByClassName("lightbox_Conjunto_8910");
    // for (var i = 0; i < elements.length; i++) {
    //   elements[i].removeAttribute("data-lightbox");
    // }

    document.getElementById("lightbox_SetimaCaixa").style.display = "none";

    document
      .getElementById("lightbox_oitavacaixa")
      .setAttribute("data-lightbox", "example-set");
    document.getElementById("lightbox_oitavacaixa").style.display = "block";
    document.getElementById("lightbox_oitavacaixa").style.width = "fit-content";
    document.getElementById("lightbox_oitavacaixa").style.margin = "0 auto";
    document.getElementById("img_8").style.display = "block";
    document.getElementById("img_8").src = "Assets/Galeria/12.png";
    document.getElementById("lightbox_oitavacaixa").href =
      "Assets/Galeria/12.png";
    document.getElementById("lightbox_nonacaixa").style.display = "none";

    document
      .getElementById("lightbox_SetimaCaixa")
      .removeAttribute("data-lightbox");

    document
      .getElementById("lightbox_nonacaixa")
      .removeAttribute("data-lightbox");
    //__________________________
    document.getElementById("Conjunto_novo").style.display = "none";
    let conjunto_novo = document.getElementsByClassName(
      "lightbox_Conjunto_novo"
    );
    for (var i = 0; i < conjunto_novo.length; i++) {
      conjunto_novo[i].removeAttribute("data-lightbox");
    }

    let Conjunto_Segundo = document.getElementsByClassName(
      "lightbox_Conjunto_Segundo"
    );
    for (var i = 0; i < Conjunto_Segundo.length; i++) {
      Conjunto_Segundo[i].setAttribute("data-lightbox", "example-set");
    }
    document
      .getElementById("lightbox_TerceiraCaixa")
      .setAttribute("data-lightbox", "example-set");
  }

  function Filtrar_Serrada() {
    //______________________________ICON_________________________________________________
    document.getElementById("Paletes_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";
    document.getElementById("Todos_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";

    document.getElementById("Serrada_Ativar_laranja").style.filter =
      "invert(50%) sepia(77%) saturate(903%) hue-rotate(338deg) brightness(108%) contrast(90%) ";
    document.getElementById("Madeira_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";

    //___________________________________________MUDAR IMAGENS_________________________________________

    document.getElementById("Galeria_PrimeiraCaixa").src =
      "Assets/Galeria/Segunda.png";
    document.getElementById("lightbox_PrimeiraCaixa").href =
      "Assets/Galeria/Segunda.png";

    document.getElementById("Galeria_SegundaCaixa").src =
      "Assets/Galeria/Quinta.png";
    document.getElementById("lightbox_SegundaCaixa").href =
      "Assets/Galeria/Quinta.png";

    // document.getElementById("Conjunto_8910").style.display = "none";
    // let elements = document.getElementsByClassName("lightbox_Conjunto_8910");
    // for (var i = 0; i < elements.length; i++) {
    //   elements[i].removeAttribute("data-lightbox");
    // }
    document.getElementById("lightbox_SetimaCaixa").style.display = "none";
    document.getElementById("img_8").style.display = "none";

    document.getElementById("lightbox_nonacaixa").style.display = "none";

    document
      .getElementById("lightbox_SetimaCaixa")
      .removeAttribute("data-lightbox");

    document
      .getElementById("lightbox_oitavacaixa")
      .removeAttribute("data-lightbox");

    document
      .getElementById("lightbox_nonacaixa")
      .removeAttribute("data-lightbox");

    document.getElementById("Conjunto_novo").style.display = "none";
    let conjunto_novo = document.getElementsByClassName(
      "lightbox_Conjunto_novo"
    );
    for (var i = 0; i < conjunto_novo.length; i++) {
      conjunto_novo[i].removeAttribute("data-lightbox");
    }

    document.getElementById("Conjunto_Segundo").style.display = "none";
    let Conjunto_Segundo = document.getElementsByClassName(
      "lightbox_Conjunto_Segundo"
    );
    for (var i = 0; i < Conjunto_Segundo.length; i++) {
      Conjunto_Segundo[i].removeAttribute("data-lightbox");
    }

    document.getElementById("Galeria_TerceiraCaixa").style.display = "none";
    document
      .getElementById("lightbox_TerceiraCaixa")
      .removeAttribute("data-lightbox");
  }

  function Filtrar_Madeira() {
    //______________________________ICON_________________________________________________

    document.getElementById("Madeira_Ativar_laranja").style.filter =
      "invert(50%) sepia(77%) saturate(903%) hue-rotate(338deg) brightness(108%) contrast(90%)";

    document.getElementById("Paletes_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";
    document.getElementById("Todos_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)";

    document.getElementById("Serrada_Ativar_laranja").style.filter =
      "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%) ";

    //___________________________________________MUDAR IMAGENS_________________________________________
    document.getElementById("Galeria_PrimeiraCaixa").src =
      "Assets/Galeria/Terceira.png";
    document.getElementById("Galeria_SegundaCaixa").src =
      "Assets/Galeria/7.png";
    document.getElementById("Galeria_TerceiraCaixa").style.display = "unset";

    document.getElementById("Galeria_TerceiraCaixa").src =
      "Assets/Galeria/11.png";

    // document.getElementById("Conjunto_8910").style.display = "none";
    // let elements = document.getElementsByClassName("lightbox_Conjunto_8910");
    // for (var i = 0; i < elements.length; i++) {
    //   elements[i].removeAttribute("data-lightbox");
    // }
    document.getElementById("lightbox_SetimaCaixa").style.display = "none";
    document.getElementById("img_8").style.display = "none";

    document.getElementById("lightbox_nonacaixa").style.display = "none";

    document
      .getElementById("lightbox_SetimaCaixa")
      .removeAttribute("data-lightbox");

    document
      .getElementById("lightbox_oitavacaixa")
      .removeAttribute("data-lightbox");

    document
      .getElementById("lightbox_nonacaixa")
      .removeAttribute("data-lightbox");

    document.getElementById("Conjunto_novo").style.display = "none";
    let conjunto_novo = document.getElementsByClassName(
      "lightbox_Conjunto_novo"
    );
    for (var i = 0; i < conjunto_novo.length; i++) {
      conjunto_novo[i].removeAttribute("data-lightbox");
    }

    document.getElementById("Conjunto_Segundo").style.display = "none";
    let Conjunto_Segundo = document.getElementsByClassName(
      "lightbox_Conjunto_Segundo"
    );
    for (var i = 0; i < Conjunto_Segundo.length; i++) {
      Conjunto_Segundo[i].removeAttribute("data-lightbox");
    }

    //___________________________LIGHTBOX_____________________________________

    document.getElementById("lightbox_PrimeiraCaixa").href =
      "Assets/Galeria/Terceira.png";
    document.getElementById("lightbox_SegundaCaixa").href =
      "Assets/Galeria/7.png";

    document
      .getElementById("lightbox_TerceiraCaixa")
      .setAttribute("data-lightbox", "example-set");
    document.getElementById("Galeria_TerceiraCaixa").style.display =
      "inline-block";

    document.getElementById("lightbox_TerceiraCaixa").href =
      "Assets/Galeria/11.png";

    //________________________________________________________________
  }
}

export default Galeria;
