import React from "react";

import { HashLink as Link } from "react-router-hash-link";

function Nav() {
  return (
    <div>
      {/* _____________________________________________TODO O HEADER MOBILE_____________________________________________ */}
      <header className="header_hamburger col-12 px-0" id="header_hamburger">
        <div className="row" id="hamburger_row">
          <div className="nav_idioma text-center" id="Hamburger_idioma">
            <div className=" dropdown">
              <button
                className="btn_idioma dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="Assets/Nav/Portugal.png" alt="Bandeira de Portugal" />
                <span>PT-PT</span>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-dark"
                id="hamburger_drop"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <a className="dropdown-item" onClick={MudarIdioma}>
                    <img
                      src="Assets/Nav/UK.svg"
                      alt="Bandeira do Reino Unido"
                    />
                    EN-UK
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* _____________________________________________HAMBURGER____________________________________ */}
        <nav className="navbar navbar-light bg-light" id="hamburger_nav">
          <div className="container-fluid">
            <a
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img
                src="Assets/Hamburger/Logo_Responssivo.svg"
                className="i_logo mx-1 mx-md-2"
                alt="Logótipo de Santos e Silva"
                id="inicio"
              />
            </a>
            <button
              className="navbar-toggler ms-auto"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#navbarToggleExternalContent3"
              aria-controls="navbarToggleExternalContent3"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="i_contentor">
                <img
                  src="Assets/Hamburger/Hamburger.svg"
                  className="i_hamburger"
                  alt="Paletes"
                />
              </i>
            </button>
          </div>
        </nav>
        <div className="collapse 2" id="navbarToggleExternalContent3">
          <div className="bg-light  ">
            <a
              onClick={() => {
                window.location.href = "/#/produtos";
              }}
            >
              <button
                className="btn btn-link btn-block border-bottom m-0 "
                id="btn_hamburger"
              >
                PRODUTOS
              </button>
            </a>
            <a
              onClick={() => {
                window.location.href = "/#/sobre-nos";
              }}
            >
              <button
                className="btn btn-link btn-block border-bottom m-0"
                id="btn_hamburger"
              >
                SOBRE NÓS
              </button>
            </a>
            <a
              onClick={() => {
                window.location.href = "/#/galeria";
              }}
            >
              <button
                className="btn btn-link btn-block border-bottom m-0"
                id="btn_hamburger"
              >
                GALERIA
              </button>
            </a>
            <a
              onClick={() => {
                window.location.href = "/#/contactos";
              }}
            >
              <button
                className="btn btn-link btn-block border-bottom m-0"
                id="btn_hamburger"
              >
                CONTACTOS
              </button>{" "}
            </a>
          </div>
        </div>
      </header>
      {/* _____________________________________________HEADER NORMAL_________________________________________________________*/}
      <header className="row header_normal" id="header_normal">
        <div className="nav_idioma">
          <div className="drop_idioma dropdown float-end">
            <button
              className="btn_idioma dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="Assets/Nav/Portugal.png" alt="Bandeira de Portugal" />
              <span>PT-PT</span>
            </button>
            <ul
              className="dropdown-menu dropdown-menu-dark"
              aria-labelledby="dropdownMenuButton2"
            >
              <li>
                <a className="dropdown-item" onClick={MudarIdioma}>
                  <img src="Assets/Nav/UK.svg" alt="Bandeira do Reino Unido" />
                  EN-UK
                </a>
              </li>
            </ul>
          </div>
        </div>
        <nav>
          <a
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img
              className="logo"
              src="Assets/Nav/Logo1.svg"
              alt="Logótipo de Santos e Silva"
              id="topo"
            />{" "}
          </a>
          <ul className="nav_lista">
            <li className="nav_underline" id="nav_underline_Produtos">
              <a
                onClick={() => {
                  window.location.href = "/#/produtos";
                }}
              >
                {" "}
                PRODUTOS
              </a>
            </li>

            <li className="nav_underline" id="nav_underline_SobreNos">
              <a
                onClick={() => {
                  window.location.href = "/#/sobre-nos";
                }}
              >
                SOBRE NÓS
              </a>
            </li>

            <li className="nav_underline" id="nav_underline_Galeria">
              <a
                onClick={() => {
                  window.location.href = "/#/galeria";
                }}
              >
                GALERIA
              </a>
            </li>
            <li className="nav_underline" id="nav_underline_Contactos">
              <a
                onClick={() => {
                  window.location.href = "/#/contactos";
                }}
              >
                CONTACTOS
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );

  function MudarIdioma() {
    const RELATIVE_PATH = window.location.hash;

    if (RELATIVE_PATH === "" || RELATIVE_PATH === "/") {
      window.location.href = "/#/home";
    } else if (RELATIVE_PATH.includes("#/home")) {
      window.location.href = "/";
    } else if (RELATIVE_PATH.includes("#/produtos")) {
      window.location.href = "/#/products";
    } else if (RELATIVE_PATH.includes("#/products")) {
      window.location.href = "/#/produtos";
    } else if (RELATIVE_PATH.includes("#/sobre-nos")) {
      window.location.href = "/#/about-us";
    } else if (RELATIVE_PATH.includes("#/about-us")) {
      window.location.href = "/#/sobre-nos";
    } else if (RELATIVE_PATH.includes("#/galeria")) {
      window.location.href = "/#/gallery";
    } else if (RELATIVE_PATH.includes("#/gallery")) {
      window.location.href = "/#/galeria";
    } else if (RELATIVE_PATH.includes("#/contactos")) {
      window.location.href = "/#/contacts";
    } else if (RELATIVE_PATH.includes("#/contacts")) {
      window.location.href = "/#/contactos";
    } else {
      window.location.href = "/#/home";
    }
  }

  // function MudarIdioma() {
  //   const RELATIVE_PATH = window.location.hash;

  //   switch (RELATIVE_PATH) {
  //     case "":
  //       window.location.href = "/#/home";

  //       break;
  //     case "/":
  //       window.location.href = "/#/home";

  //       break;
  //     case "#/home":
  //       window.location.href = "/";

  //       break;

  //     case "#/produtos":
  //       window.location.href = "/#/products";
  //       break;
  //     case "#/products":
  //       window.location.href = "/#/produtos";
  //       break;

  //     case "#/sobre-nos":
  //       window.location.href = "/#/about-us";
  //       break;
  //     case "#/about-us":
  //       window.location.href = "/#/sobre-nos";
  //       break;

  //     case "#/galeria":
  //       window.location.href = "/#/gallery";
  //       break;
  //     case "#/gallery":
  //       window.location.href = "/#/galeria";
  //       break;

  //     case "#/contactos":
  //       window.location.href = "/#/contacts";
  //       break;
  //     case "#/contacts":
  //       window.location.href = "/#/contactos";
  //       break;

  //     default:
  //       break;
  //   }
  // }
}

export default Nav;
