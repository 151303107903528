import React, { useEffect } from "react";
import Nav_EN from "./Components/Nav_EN.js";
import Footer_EN from "./Components/Footer_EN.js";
import { HashLink as Link } from "react-router-hash-link";
import btn_paletes from "../Assets_EN/Produtos_EN/btn_paletesEN.svg";
import btn_serrada from "../Assets_EN/Produtos_EN/btn_serradaEN.svg";
import btn_subprodutos from "../Assets_EN/Produtos_EN/btn_subprodutosEN.svg";
import BY from "../Assets_EN/Produtos_EN/BY.svg";

function Produtos_EN() {
  useEffect(() => {
    document.title = "Products - Santos & Silva";
    document.documentElement.lang = "en-uk";
    Sublinhado_Produtos();
  }, []);
  return (
    <div>
      <Nav_EN />

      <main className="main_xs_prod" id="cima">
        {/*___________________________________________________SLIDER____________________________________________________*/}
        <div className="row " id="Ancora_Home_topo">
          <div
            id="carouselExampleIndicators"
            className="carousel slide px-0 "
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="Produtos_indicadores">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                id="btn_Paletes"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="Slide 1"
                style={{
                  background: `url(${btn_paletes})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                id="btn_Madeira"
                data-bs-slide-to={1}
                aria-label="Slide 2"
                style={{
                  background: `url(${btn_serrada})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                id="btn_Material"
                data-bs-slide-to={2}
                aria-label="Slide 3"
                style={{
                  background: `url(${btn_subprodutos})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
            <div className="carousel-inner" id="Produtos_Tamanho_slider">
              <div className="carousel-item active" id="Contentor_Pale">
                <Link to="#Ancora_Produtos_Paletes">
                  <div className="cont_produtos_img1">
                    <img
                      src="Assets/Produtos/Paletes.png"
                      id="imagem_principal_xs"
                      className="d-block w-100"
                      alt="Paletes"
                    />
                  </div>
                </Link>
              </div>
              <div className="carousel-item" id="Contentor_Madeira">
                <Link to="#Ancora_Produtos_Madeira">
                  <div className="cont_produtos_img2">
                    <img
                      src="Assets/Produtos/Madeira.png"
                      id="imagem_principal_xs"
                      className="d-block w-100"
                      alt="Madeira Serrada"
                    />
                  </div>
                </Link>
              </div>
              <div className="carousel-item" id="Contentor_Material">
                <Link to="#Ancora_Produtos_Material">
                  <div className="cont_produtos_img3">
                    <img
                      src="Assets/Produtos/Material.png"
                      id="imagem_principal_xs"
                      className="d-block w-100"
                      alt="SubProdutos"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________SEGUNDA____________________________________________________*/}
        <div className="row Produtos_Segunda ">
          <div
            className="offset-md-1 col-md-11 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0 omsegunda"
            id="Segunda-Produtos-xs"
          >
            <div
              className="Produto_Segunda_Margem"
              id="Ancora_Produtos_Paletes"
            >
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <span className="Span_HomeProdutos">Pallets</span>
            </div>
            {/*__________CONJUNTO 1____________________________________________________________________________*/}
            <div className="mb-5 Produtos_Contentor1 col-xs-12 col-sm-12">
              {/*__________COMPONENTE1___________________________________________*/}
              <div
                className="Produto_Segunda_Contentor col-xs-12 "
                data-aos="fade-right"
              >
                <div className="Produto_Segunda_Img">
                  <img
                    src="Assets/Produtos/2.png"
                    alt="Palete"
                    id="first_produto_segunda"
                  />
                </div>
                <div className="Produto_Segunda_Linha mx-3">
                  <div>
                    <img
                      src="Assets/Home/Linha.svg"
                      className="Produto_Segunda_Linha_img"
                      alt="Paletes"
                    />
                  </div>
                  <div>
                    <span className="Produto_Segunda_Titulo">
                      EURO PALLET EUPAL1
                    </span>
                  </div>
                  <div className="Produto_Segunda_Medidas mt-2 ">
                    <div>
                      <strong>800mm</strong> <br /> <span>Length</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>1200mm</strong> <br /> <span>Width</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>144mm</strong> <br /> <span>Height</span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>12kg</strong>
                  </div>
                  <div className="Peso">Weight</div>
                  <div className="mt-2">*Pine wood.</div>
                </div>
              </div>
              {/*__________COMPONENTE2___________________________________________*/}
              <div
                className="Produto_Segunda_Contentor omg col-xs-12  offset-md-1 marge_de_seguranca "
                id="Segunda-Produtos-xs"
                data-aos="fade-left"
              >
                <div className="Produto_Segunda_Img">
                  <img src="Assets/Produtos/3.png" alt="Palete" />
                </div>
                <div className="Produto_Segunda_Linha mx-3">
                  <div>
                    <img
                      src="Assets/Home/Linha.svg"
                      className="Produto_Segunda_Linha_img"
                      alt="Paletes"
                    />
                  </div>
                  <div>
                    <span className="Produto_Segunda_Titulo">
                      EUROPALET EPAL 3
                    </span>
                  </div>
                  <div className="Produto_Segunda_Medidas mt-2 ">
                    <div>
                      <strong>1000mm</strong> <br /> <span>Length</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>600mm</strong> <br /> <span>Width</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>150mm</strong> <br /> <span>Height</span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>8kg</strong>
                  </div>
                  <div className="Peso">Weight</div>
                  <div className="mt-2">*Pine wood.</div>
                </div>
              </div>
            </div>
            {/*__________CONJUNTO 2____________________________________________________________________________*/}
            <div
              className="mb-5 Produtos_Contentor1 col-xs-12 col-sm-12"
              id="Segunda-Produtos-xs"
            >
              {/*__________COMPONENTE3___________________________________________*/}
              <div className="Produto_Segunda_Contentor " data-aos="fade-right">
                <div className="Produto_Segunda_Img">
                  <img src="Assets/Produtos/4.png" alt="Palete" />
                </div>
                <div className="Produto_Segunda_Linha mx-3">
                  <div>
                    <img
                      src="Assets/Home/Linha.svg"
                      className="Produto_Segunda_Linha_img"
                      alt="Paletes"
                    />
                  </div>
                  <div>
                    <span className="Produto_Segunda_Titulo">
                      EURO PALLET TYPE 1
                    </span>
                  </div>
                  <div className="Produto_Segunda_Medidas mt-2 ">
                    <div>
                      <strong>1100mm</strong> <br /> <span>Length</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>700mm</strong> <br /> <span>Width</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>150mm</strong> <br /> <span>Height</span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>10kg</strong>
                  </div>
                  <div className="Peso">Weight</div>
                  <div className="mt-2">*Pine wood.</div>
                </div>
              </div>
              {/*__________COMPONENTE4___________________________________________*/}
              <div
                className="Produto_Segunda_Contentor col-xs-12 offset-md-1 marge_de_seguranca"
                id="Segunda-Produtos-xs"
                data-aos="fade-left"
              >
                <div className="Produto_Segunda_Img">
                  <img src="Assets/Produtos/5.png" alt="Palete" />
                </div>
                <div className="Produto_Segunda_Linha mx-3">
                  <div>
                    <img
                      src="Assets/Home/Linha.svg"
                      className="Produto_Segunda_Linha_img"
                      alt="Paletes"
                    />
                  </div>
                  <div>
                    <span className="Produto_Segunda_Titulo">
                      EURO PALLET TYPE EPAL 3
                    </span>
                  </div>
                  <div className="Produto_Segunda_Medidas mt-2 ">
                    <div>
                      <strong>1200mm</strong> <br /> <span>Length</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>600mm</strong> <br /> <span>Width</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>100mm</strong> <br /> <span>Height</span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>10kg</strong>
                  </div>
                  <div className="Peso">Weight</div>
                  <div className="mt-2">*Pine wood.</div>
                </div>
              </div>
            </div>
            {/*__________CONJUNTO 3____________________________________________________________________________*/}
            <div className="mb-5 Produtos_Contentor1">
              {/*__________COMPONENTE5___________________________________________*/}
              <div className="Produto_Segunda_Contentor" data-aos="fade-right">
                <div className="Produto_Segunda_Img">
                  <img src="Assets/Produtos/7.png" alt="Palete" />
                </div>
                <div className="Produto_Segunda_Linha mx-3">
                  <div>
                    <img
                      src="Assets/Home/Linha.svg"
                      className="Produto_Segunda_Linha_img"
                      alt="Paletes"
                    />
                  </div>
                  <div>
                    <span className="Produto_Segunda_Titulo">FLAP PALLET</span>
                  </div>
                  <div className="Produto_Segunda_Medidas mt-2 ">
                    <div>
                      <strong>900mm</strong> <br /> <span>Length</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>700mm</strong> <br /> <span>Width</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>105mm</strong> <br /> <span>Height</span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>16kg</strong>
                  </div>
                  <div className="Peso">Weight</div>
                  <div className="mt-2">*Pine wood.</div>
                </div>
              </div>
              {/*__________COMPONENTE6___________________________________________*/}
              <div
                className="Produto_Segunda_Contentor marge_de_seguranca col-xs-12  offset-md-1"
                data-aos="fade-left"
              >
                <div className="Produto_Segunda_Img">
                  <img src="Assets/Produtos/2.png" alt="Palete" />
                </div>
                <div className="Produto_Segunda_Linha mx-3">
                  <div>
                    <img
                      src="Assets/Home/Linha.svg"
                      className="Produto_Segunda_Linha_img"
                      alt="Paletes"
                    />
                  </div>
                  <div>
                    <span className="Produto_Segunda_Titulo">
                      CLOSED TOP PALLET
                    </span>
                  </div>
                  <div className="Produto_Segunda_Medidas mt-2 ">
                    <div>
                      <strong>1300mm</strong> <br /> <span>Length</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>700mm</strong> <br /> <span>Width</span>
                    </div>
                    <div className="Produto_Segunda_Por">
                      <img src={BY} alt="Por" />
                    </div>
                    <div>
                      <strong>180mm</strong> <br /> <span>Height</span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>18kg</strong>
                  </div>
                  <div className="Peso">Weight</div>
                  <div className="mt-2">*Pine wood.</div>
                </div>
              </div>
            </div>
            {/*__________SEGUNDA-BOTAO____________________________________________________________________________*/}
          </div>
          <div className="text-center  col-xs-12 col-sm-12  offset-lg-1 col-lg-11 Botao_VerPedido1">
            <Link to="/contacts#Ancora_Contactos">
              <button
                type="button"
                className="btn btn-outline-warning shadow-none col-md-5 col-xs-12  col-sm-12  "
                id="btn_ProdutosProdutos"
              >
                Send Request
              </button>
            </Link>
          </div>
        </div>
        {/*__________________________________________________TERCEIRA____________________________________________________*/}
        <div className="row Produtos_Segunda">
          <div
            className="offset-xl-1 col-xl-11 offset-lg-1 col-lg-10 col-xs-12 offset-xs-0 col-sm-10 offset-sm-1 col-md-10 offset-md-1 terc_prd"
            id="aumento_produto_4k"
          >
            <div
              className="Produto_Segunda_Margem Produto_Quarta_Margem"
              id="Ancora_Produtos_Madeira"
            >
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <span className="Span_HomeProdutos">Sawn Timber</span>
            </div>
            <div
              className="produtos_figuras_xs"
              id="produtos_figuras_xs"
              data-aos="flip-left"
            >
              {/*_________________________1____________________________*/}
              <figure className="MadeiraSerrada1 ">
                <img
                  src="Assets/Produtos/MadeiraSerrada1.png"
                  alt="figure alt text"
                />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">States</div>
                  <div className="Fabricamos">
                    We manufacture made-to-measure bed frames
                  </div>
                </figcaption>
              </figure>
              {/*_________________________2____________________________*/}
              <figure
                className="MadeiraSerrada1 MadeiraSerrada_Margem "
                id="omg_imagem"
              >
                <img
                  src="Assets/Produtos/MadeiraSerrada2.png"
                  alt="figure alt text"
                />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">Malot</div>
                  <div className="Fabricamos">Measurements per m2</div>
                </figcaption>
              </figure>
              {/*________________________3____________________________*/}
              <figure
                className="MadeiraSerrada1  MadeiraSerrada_Margem conj_md_madeira"
                id="omg_imagem"
              >
                <img
                  src="Assets/Produtos/Travessas.png"
                  alt="figure alt text"
                />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">Traverses</div>
                  <div className="Fabricamos">Suitable for railways</div>
                </figcaption>
              </figure>
              {/*________________________4____________________________*/}
              <figure
                className="MadeiraSerrada1   MadeiraSerrada_Margem conj_md_madeira"
                id="omg_imagem"
              >
                <img src="Assets/Produtos/Barrotes.png" alt="figure alt text" />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">Beams</div>
                  <div className="Fabricamos">For pallet production</div>
                </figcaption>
              </figure>
            </div>
            {/*__________TERCEIRA-BOTAO____________________________________________________________________________*/}
          </div>
          <div
            className="text-center Botao_VerPedido1 col-sm-12 offset-lg-1 col-lg-11 "
            id="Botao_VerPedido3"
          >
            <Link to="/contacts#Ancora_Contactos">
              <button
                type="button"
                className="btn btn-outline-warning shadow-none col-5   "
                id="btn_ProdutosProdutos"
              >
                Send Request
              </button>
            </Link>
          </div>
        </div>
        {/*__________________________________________________QUARTA____________________________________________________*/}
        <div className="row Produtos_Segunda">
          <div
            className="offset-xl-2 col-xl-10  col-xs-12 offset-xs-0  col-sm-12 offset-sm-0 aumento_quarta_4k"
            id="produtos_quarta_xs"
          >
            <div
              className="Produto_Segunda_Margem  Produto_Quarta_Margem"
              id="Ancora_Produtos_Material"
            >
              <img src="Assets/Home/Linha.svg" alt="Paletes" />
              <span className="Span_HomeProdutos">SubProducts</span>
            </div>
            <div className="juntos_prod_1920" data-aos="flip-up">
              {/*_________________________1____________________________*/}
              <figure className="MadeiraSerrada1 " id="MadeiraSerrada1_marg">
                <img src="Assets/Produtos/Serrim.png" alt="figure alt text" />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">Sawdust</div>
                  <div className="Fabricamos">Sale per m3</div>
                </figcaption>
              </figure>
              {/*_________________________2____________________________*/}
              <figure
                className="MadeiraSerrada1 MadeiraSerrada_Margem "
                id="margem_final"
              >
                <img src="Assets/Produtos/Estilha.png" alt="figure alt text" />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">Wood chips</div>
                  <div className="Fabricamos">Sale per m3</div>
                </figcaption>
              </figure>
              {/*________________________3____________________________*/}
              <figure
                className="MadeiraSerrada1 MadeiraSerrada_Margem"
                id="margem_final"
              >
                <img src="Assets/Produtos/Casca.png" alt="figure alt text" />
                <figcaption>
                  <img
                    src="Assets/Home/Linha.svg"
                    id="Produto_Segunda_Linha_img"
                    className="Produto_Segunda_Linha_img"
                    alt="Paletes"
                  />
                  <div className="Estados">Pine Bark</div>
                  <div className="Fabricamos">Sale per m3</div>
                </figcaption>
              </figure>
            </div>
            {/*__________QUARTA-BOTAO____________________________________________________________________________*/}
          </div>
          <div
            className="text-center offset-lg-1 col-lg-11 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0 Botao_VerPedido1"
            id="Botao_VerPedido3"
          >
            <Link to="/contacts#Ancora_Contactos">
              <button
                type="button"
                className="btn btn-outline-warning shadow-none col-5   "
                id="btn_ProdutosProdutos"
              >
                Send Request
              </button>
            </Link>
          </div>
        </div>
      </main>

      <Footer_EN />
    </div>
  );
  function Sublinhado_Produtos() {
    document.getElementById("nav_underline_Produtos").style.cssText =
      " border-bottom: 4px solid #f27f3d;";
    var styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.innerHTML =
      "#nav_underline_Produtos:after {content: unset; position: unset; bottom: unset; left: unset; width: unset; height: unset;background-color: unset;opacity: unset;transition:unset;}";
  }
}

export default Produtos_EN;
