import React, { useEffect } from "react";
import Nav_EN from "./Components/Nav_EN.js";
import Footer_EN from "./Components/Footer_EN.js";
import fazemosEN from "../Assets_EN/SobreNos_EN/fazemosEN.svg";
import segurancaEN from "../Assets_EN/SobreNos_EN/segurancaEN.svg";
import beneficiosEN from "../Assets_EN/SobreNos_EN/beneficiosEN.svg";
import ambienteEN from "../Assets_EN/SobreNos_EN/ambienteEN.svg";
import { HashLink as Link } from "react-router-hash-link";

function SobreNos_EN() {
  useEffect(() => {
    document.title = "About us - Santos & Silva";
    document.documentElement.lang = "en-uk";
    Sublinhado_SobreNos();
  }, []);

  return (
    <div>
      <Nav_EN />

      <main className="main_sobrenos" id="cima">
        {/*___________________________________________________SLIDER____________________________________________________*/}
        <div className="row " id="Ancora_Home_topo">
          <div
            id="carouselExampleIndicators"
            className="carousel slide px-0 "
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="carousel_sobrenos">
              <Link to="#Ancora_SobreNos_Fazemos">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Fazemos"
                  className="SobreNos_Botoes"
                  style={{
                    background: `url(${fazemosEN})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Link>
              <Link to="#Ancora_SobreNos_Seguranca">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Seguranca"
                  className="SobreNos_Botoes"
                  style={{
                    background: `url(${segurancaEN})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Link>
              <Link to="#Ancora_SobreNos_Beneficios">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Beneficios"
                  className="SobreNos_Botoes"
                  style={{
                    background: `url(${beneficiosEN})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Link>
              <Link to="#Ancora_SobreNos_Ambiente">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  id="btn_Ambiente"
                  className="SobreNos_Botoes"
                  style={{
                    background: `url(${ambienteEN})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Link>
            </div>
            <div className="carousel-inner" id="Tamanho_slider">
              <div
                className="carousel-item active contentor_sobrenos"
                id="Contentor_Slogan"
              >
                <img
                  src="Assets/SobreNós/Sobre.png"
                  className="d-block w-100 SObre_imagem"
                  alt="Paletes"
                />
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________SEGUNDA____________________________________________________*/}
        <div className="row SobreNos_Segunda">
          <div className="offset-xl-1 col-xl-11 col-sm-12  col-xs-12 SobreNos_Colunas">
            <div
              className="Produto_Segunda_Margem Fazemos_Segunda col-xl-4  col-sm-12 col-xs-12"
              id="Ancora_SobreNos_Fazemos"
              data-aos="fade-right"
            >
              <img src="Assets/Home/Linha.svg" alt="O que fazemos" />
              <div className="O_que_fazemos mt-2" id="caracteristicas">
                What we do
              </div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  Santos &amp; Silva was born in 1967 in São Pedro do Sul
                  district of Viseu, with activity in wood sawmill and
                  carpentry.
                </p>
                <p>
                  The company's activity is divided into two main business areas
                  sawmilling and manufacture of pallets, wooden packaging and
                  thermo-treated wood, europalete(EPAL)
                </p>
                <p>
                  and among various other wood products: such as pine bark bark,
                  chips and sawdust.
                </p>
                <p>
                  Today, it is a company that is focused on the future and on
                  innovation in technology for greater production efficiency and
                  quality of the finished product.
                </p>
              </div>
              <div className="Fazemos_Premiado mt-4 text-center">
                <div>Awarded Leader of Excellence</div>
                <div>
                  <img
                    src="Assets/SobreNós/PME.png"
                    className="mt-3"
                    alt="Prémios de excelência 2016"
                  />
                </div>
              </div>
            </div>
            <div
              className="Fazemos_Premiado_Imagem nova_coluna_sobrenos col-xs-12 "
              data-aos="fade-left"
            >
              <img
                src="Assets/SobreNós/Segunda.png"
                className="imagem_primeira_sobrenos_xs"
                alt="O que fazemos"
              />
            </div>
          </div>
        </div>
        {/*___________________________________________________TERCEIRA____________________________________________________*/}
        <div className="row SobreNos_Segunda" id="terceira_sobrenos_lg">
          <div className="offset-1 col-11 SobreNos_Colunas">
            <div
              className="Seguranca_Imagem mt-5 "
              id="Ancora_SobreNos_Seguranca"
              data-aos="fade-right"
            >
              <img
                src="Assets/SobreNós/Terceira.png"
                alt="Segurança"
                id="seguranca"
              />
            </div>
            <div
              className="Produto_Segunda_Margem SobreNos_Terceira col-4"
              id="Ancora_Produtos_Paletes"
              data-aos="fade-left"
            >
              <img src="Assets/Home/Linha.svg" alt="Segurança" />
              <div className="O_que_fazemos mt-2">Safety</div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  Our company complies with ISPM Standard 15, which aims at the
                  detection and elimination of the Pine Wood Nematode and other
                  harmful organisms, through phytosanitary treatment procedures
                  phytosanitary treatment by heat - thermal shock (HT).
                </p>
                <p>
                  This rigorous process ensures a better quality and durability
                  of our wood
                </p>
                <p>
                  If you are in doubt about some type of pallet or lumber or
                  sawn wood ideal for your business, talk to us without
                  commitment. We will give you the most adequate solution for
                  your your business.
                </p>
              </div>
              <div className="MarcaDePalete mt-4 text-center">
                <img
                  src="Assets/SobreNós/Marca.png"
                  className="mt-3"
                  alt="Marca de Palete"
                />
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________TERCEIRA-XS____________________________________________________*/}
        <div
          className="row SobreNos_Segunda text-center "
          id="terceira_sobrenos_xs"
        >
          <div className=" col-12 SobreNos_Colunas ">
            <div
              className="Produto_Segunda_Margem SobreNos_Terceira mudar_marge_terceira"
              data-aos="fade-right"
            >
              <img src="Assets/Home/Linha.svg" alt="Segurança" id="seguranca" />
              <div className="O_que_fazemos mt-2">Safety</div>
              <div className="Fazemos_texto  mt-3">
                <p>
                  Our company complies with ISPM Standard 15, which aims at the
                  detection and elimination of the Pine Wood Nematode and other
                  harmful organisms, through phytosanitary treatment procedures
                  phytosanitary treatment by heat - thermal shock (HT).
                </p>
                <p>
                  This rigorous process ensures a better quality and durability
                  of our wood
                </p>
                <p>
                  If you are in doubt about some type of pallet or lumber or
                  sawn wood ideal for your business, talk to us without
                  commitment. We will give you the most adequate solution for
                  your your business.
                </p>
              </div>
              <div className="MarcaDePalete mt-4 text-center">
                <img
                  src="Assets/SobreNós/Marca.png"
                  className="mt-3"
                  alt="Marca de Palete"
                />
              </div>
            </div>
            <div
              className="Seguranca_Imagem mt-5 col-12 vh-100 px-0 "
              id="ancora_imagem_terceira_xs"
              data-aos="fade-left"
            ></div>
          </div>
        </div>
        {/*___________________________________________________QUARTA____________________________________________________*/}
        <div className="row SobreNos_Segunda">
          <div className="offset-xl-1 col-xl-11 col-sm-12 col-xs-12 SobreNos_Colunas">
            <div
              className="Produto_Segunda_Margem Fazemos_Segunda  col-xl-4 col-sm-4  col-xs-12"
              id="Ancora_SobreNos_Beneficios"
              data-aos="fade-right"
            >
              <img
                src="Assets/Home/Linha.svg"
                alt="Beneficios"
                id="beneficios"
              />
              <div className="O_que_fazemos mt-2">Benefits</div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  Our company has its own fleet which guarantees a complete a
                  complete, fast and more flexible service to the client.
                </p>
                <p>
                  {" "}
                  Our company's actions are guided by the application of Value
                  engineering with quality preservation.
                </p>
                <p>
                  We do this by bringing all the technique, competence and
                  experience of our engineers to your project, at whatever point
                  we become involved - in the construction itself, right from
                  project development.
                </p>
                <p>
                  We also take our rigorous supplier qualification process and
                  practices in and practices in safety, sustainability,
                  customization and performance customization and performance
                  that are standard of Santos &amp; and are present in all the
                  projects where we leave our mark. our brand
                </p>
              </div>
            </div>
            <div
              className="Fazemos_Premiado_Imagem "
              id="quarta_noxs"
              data-aos="fade-left"
            >
              <img
                src="Assets/SobreNós/Quarta.png"
                className="quarta_desaparece_xs"
                alt="Benefícios"
              />
            </div>
          </div>
        </div>
        {/*___________________________________________________QUINTA____________________________________________________*/}
        <div className="row SobreNos_Segunda" id="quinta_sobrenos">
          <div className="offset-1 col-11 SobreNos_Colunas">
            <div
              className="Seguranca_Imagem mt-5"
              id="Ancora_SobreNos_Ambiente"
              data-aos="fade-right"
            >
              <img
                src="Assets/SobreNós/Quinta.png"
                alt="Ambiente"
                id="ambiente"
              />
            </div>
            <div
              className="Produto_Segunda_Margem SobreNos_Terceira col-4"
              id="Ancora_Produtos_Paletes"
              data-aos="fade-left"
            >
              <img src="Assets/Home/Linha.svg" alt="Ambiente" />
              <div className="O_que_fazemos mt-2">Environment</div>
              <div className="Fazemos_texto text-justify mt-3">
                <p>
                  Environmental protection and sustainability have become two
                  essential demands on society as a whole.
                </p>
                <p>
                  The adoption of a sustainable attitude requires a
                  transformation in production in production processes, daily
                  life and the mentality of all us.
                </p>
                <p>
                  Santos &amp; Silva plays a key role when the subject
                  environment and sustainability.
                </p>
                <p>
                  Our company has a team specialised in forest exploitation with
                  a careful selection of the resources necessary.
                </p>
                <p>
                  Nowadays due to the increasing problem of deforestation, we
                  contribute to the good of our planet by planting trees.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*___________________________________________________QUINTA-XS____________________________________________________*/}
        <div className="row SobreNos_Segunda " id="quinta_sobrenos_xs">
          <div className="col-12 SobreNos_Colunas">
            <div className="Produto_Segunda_Margem SobreNos_Terceira " id>
              <img src="Assets/Home/Linha.svg" alt="Ambiente" />
              <div className="O_que_fazemos mt-2">Ambiente</div>
              <div className="Fazemos_texto  mt-3">
                <p>
                  Environmental protection and sustainability have become two
                  essential demands on society as a whole.
                </p>
                <p>
                  The adoption of a sustainable attitude requires a
                  transformation in production in production processes, daily
                  life and the mentality of all us.
                </p>
                <p>
                  Santos &amp; Silva plays a key role when the subject
                  environment and sustainability.
                </p>
                <p>
                  Our company has a team specialised in forest exploitation with
                  a careful selection of the resources necessary.
                </p>
                <p>
                  Nowadays due to the increasing problem of deforestation, we
                  contribute to the good of our planet by planting trees.
                </p>
              </div>
            </div>
            <div
              className="Seguranca_Imagem mt-5 col-12 vh-100 px-0 "
              id="Ancora_SobreNos_Ambiente"
            ></div>
          </div>
        </div>
      </main>

      <Footer_EN />
    </div>
  );
  function Sublinhado_SobreNos() {
    document.getElementById("nav_underline_SobreNos").style.cssText =
      " border-bottom: 4px solid #f27f3d;";
    var styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.innerHTML =
      "#nav_underline_SobreNos:after {content: unset; position: unset; bottom: unset; left: unset; width: unset; height: unset;background-color: unset;opacity: unset;transition:unset;}";
  }
}
export default SobreNos_EN;
