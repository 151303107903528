import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function Footer() {
  return (
    <div>
      {/*___________________________________________________FOOTER____________________________________________________*/}
      <div className="Home_Footer_Metade mt-5 text-center home_footer_xs">
        <Link to="#cima">
          <img
            src="Assets/Footer/Metade.svg"
            className="Metade"
            alt="Contacte-nos"
          />
        </Link>
      </div>
      <footer className="row footer_principal">
        <div className="col-11 offset-1 ">
          <Link to="/#cima">
            <img
              src="Assets/Footer/Logotipo.svg"
              className="Home_Footer_Logo "
              alt="Logótipo de Santos e Silva"
            />{" "}
          </Link>
          <div className="Home_Footer_Meio">
            <div className="Home_Footer_Movimentar mt-5 ">
              <div className="Home_Footer_Contentor">
                <ul className="Home_Footer_ul">
                  <li>Acesso Rápido:</li>
                  <Link to="/produtos#cima">
                    <li className="mt-2 Home_Footer_ul_link">Produtos</li>{" "}
                  </Link>
                  <Link to="/contactos#cima">
                    <li className="mt-2 Home_Footer_ul_link">Contactos</li>{" "}
                  </Link>
                </ul>
                <ul className="Home_Footer_ul">
                  <li>&nbsp;</li>

                  <Link to="/sobre-nos#cima">
                    <li className="mt-2 Home_Footer_ul_link">Sobre nós</li>
                  </Link>

                  <Link to="/galeria#cima">
                    <li className="mt-2 Home_Footer_ul_link">Galeria</li>{" "}
                  </Link>
                </ul>
              </div>
              <div className="Home_Footer_Contentor" id="Home_Footer_Segue">
                <ul className="Home_Footer_ul" id="Home_Footer_ulSegundo">
                  <li>Segue-nos:</li>
                  <li className="mt-2 Home_Footer_Contentor">
                    <a
                      href="https://www.facebook.com/santosesilvalda"
                      target="_blank"
                    >
                      <img
                        src="Assets/Footer/Facebook.svg"
                        className="Home_Footer_ul_link"
                        alt="Facebook"
                      />{" "}
                    </a>
                  </li>
                  <li className="mt-2 Home_Footer_Contentor">
                    <a
                      href="https://pt.linkedin.com/company/santos-silva-lda"
                      target="_blank"
                    >
                      <img
                        src="Assets/Footer/Linkedin.svg"
                        className="Home_Footer_ul_link"
                        alt="Linkedin"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Home_Footer_FundosCont">
              <a
                href="https://www.santosesilva.pt/ficha_projeto_santos_e_silva.pdf"
                target="_blank"
              >
                <img
                  src="Assets/Footer/Fundos.svg"
                  className="Home_Footer_Fundos"
                  alt="Fundos Europeus"
                />
              </a>
            </div>
          </div>
          <div className="Home_Footer_PremiosCont">
            <span className="Home_Footer_Premios">Prémios:</span>
            <div className="Home_Footer_PremiosContMDD">
              <img src="Assets/Footer/PME4.png" alt="Prémios" />
            </div>
          </div>
          <a
            onClick={() => {
              window.location.href = "/#/administracao";
            }}
          >
            <img
              src="Assets/Footer/Admin.svg"
              className="Home_Footer_Admin"
              alt="Administração"
            />{" "}
          </a>
        </div>
      </footer>
      {/*___________________________________________________FOOTER_XS____________________________________________________*/}
      <footer className="row footer_xs">
        <div className="col-12 text-center">
          <Link to="/#cima">
            <img
              src="Assets/Footer/Logotipo.svg"
              className="mt-5 foot_logo_xs"
              alt="Logótipo de Santos e Silva"
            />
          </Link>
          <div className="acesso mt-5">Acesso Rápido:</div>
          <div className="lista_footer_xs mt-4">
            <Link to="/produtos#cima">
              <span>PRODUTOS</span>
            </Link>

            <Link to="/galeria#cima">
              <span className=" offset-1 galeria_foot_xs">GALERIA</span>
            </Link>
          </div>
          <div className="lista_footer_xs mt-2">
            <Link to="/contactos#cima">
              <span>CONTACTOS</span>
            </Link>
            <Link to="/sobre-nos#cima">
              <span className="offset-1 galeria_foot_xs ">SOBRE NÓS</span>{" "}
            </Link>
          </div>
          {/*___________REDES SOCIAIS XS____________________________________________________*/}
          <div className="acesso icones_xs mt-5">Segue-nos:</div>
          <a href="https://www.facebook.com/santosesilvalda" target="_blank">
            <img src="Assets/Footer/Facebook.svg" alt="Facebook" />
          </a>
          <a
            href="https://pt.linkedin.com/company/santos-silva-lda"
            target="_blank"
          >
            <img src="Assets/Footer/Linkedin.svg" alt="Linkedin" />
          </a>
          {/*___________PREMIOS XS____________________________________________________*/}
          <div className="acesso icones_xs mt-5">Prémios:</div>
          <img src="Assets/Footer/PME4.png" className="mt-1" alt="Prémios" />
          {/*___________ADMIN_XS____________________________________________________*/}
          <div className="acesso icones_xs mt-5">
            <a
              onClick={() => {
                window.location.href = "/#/administracao";
              }}
            >
              <img
                src="Assets/Footer/Admin.svg"
                className="Home_Footer_Admin_xs"
                alt="Administração"
              />
            </a>
          </div>
          {/*___________EUROPA_XS____________________________________________________*/}
          <div className="acesso icones_xs  mt-5 pb-5">
            <a
              href="https://www.santosesilva.pt/ficha_projeto_santos_e_silva.pdf"
              target="_blank"
            >
              <img
                src="Assets/Footer/Europa_XS.svg"
                className="col-10 col-sm-7"
                alt="Administração"
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
