import React, { useEffect } from "react";

function Administracao() {
  useEffect(() => {
    document.title = "Administração - Santos & Silva";
  }, []);

  return (
    <div>
      {/* _____________________________________________TODO O HEADER MOBILE_____________________________________________ */}
      <div className="row admin_header_xs " id="hamburger_row">
        <div className="nav_idioma  text-center" id="Hamburger_idioma">
          <div className=" dropdown">
            <span className="span_admin_xs">Administração</span>
          </div>
        </div>
      </div>
      {/* _____________________________________________MAIN ADMIN_____________________________________________ */}
      <main className=" row col-12 vh-100" id="main_admin">
        <div className="col-5 px-0 Fundo_admin_container">
          <span className="Span_Administracao mx-3 ">Administração</span>
        </div>
        <div className="col-lg-5 offset-lg-1 co-sm-12 col-xs-12 text-center Admin_formulario_container">
          <div className="Admin_formulario_child col-lg-11 ">
            <img
              src="Assets/Nav/Logo.svg"
              id="Logo_Admin_xs"
              alt="Logótipo de Santos e Silva"
            />
            <form className="Admin_formulario ">
              <div
                className="form-group  col-lg-12 offset-lg-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 "
                id="formulario_text"
              >
                <input
                  type="email"
                  className="form-control mt-4 admin_form_4k  "
                  id="formulario_text"
                  placeholder="Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  aria-describedby="emailHelp"
                />
                <input
                  type="password"
                  className="form-control admin_form_4k mt-4"
                  id="formulario_text"
                  placeholder="Password"
                />
              </div>
              <div className="form-group form-check"></div>
              <button
                type="submit"
                className="btn btn-outline-warning btn_admin_4k shadow-none col-lg-12 col-sm-10 col-xs-12 col-md-8  mt-2 "
                id="btn_HomeProdutos"
              >
                Entrar
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Administracao;
